import React from "react"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./privacy.scss"

const PilotTerms = () => {
  return (
    <Layout>
      <SEO title="Pilot Terms of Use" />
      <Container className="wrapper">
        <h1>Pilot Terms of Use</h1>

        <h3>OVERVIEW</h3>
        <p>
          Thank you for your interest in piloting SHAPE. This pilot is for
          evaluation purposes only, to inform a decision on rolling out to your
          entire organisation, or pursuit of partnership between our
          organisations.
        </p>

        <h3>1. GENERAL</h3>
        <p>
          This Agreement (the “Agreement”) is entered into between your
          organisation (the “Organisation”), with name and address details as
          per the Pilot Setup Questionnaire, and SHAPE Global Ltd, 5 Ivegate,
          Leeds, LS19 7RE (“SHAPE”) on the effective date you receive access to
          the Service.
        </p>
        <p>
          This Agreement sets out the terms to which the above Parties have
          agreed to the use of the SHAPE Service (the “Service”) whereby:
        </p>

        <p>
          1.1 SHAPE agrees to provide a temporary license of no more than one
          month to the Service for Pilot (“Pilot”) purposes only
        </p>
        <p>
          1.2 The Organisation agrees to implement the Pilot as instructed
          (“Instructions”) for evaluation purposes of the Service, with a view
          to purchasing and/or a partnership decision thereafter.
        </p>
        <p>
          1.3 SHAPE reserves the right to cancel the Organisation’s access to
          the Service if it is used in any manner not authorized by SHAPE.
        </p>
        <p>
          1.4 Upon access, the Organisation’s employees shall be required to
          accept the SHAPE Privacy Policy (https://shapepowered.com/privacy).
        </p>

        <h3>2. DEFINITIONS</h3>
        <p>
          2.1 “Service” includes the SHAPE Survey, Results, communications
          (emails), documentation, and support.
        </p>
        <p>2.2 “Agreement” refers to this Agreement exclusively.</p>
        <p>
          2.3 “Pilot” refers to the activity of a limited number of people using
          the Service for evaluation purposes.
        </p>
        <p>
          2.4 “Instructions” refers to the written documentation provided by
          SHAPE that outlines the roles, process, and activities to be performed
          by the Organisation during the Pilot period.
        </p>
        <p>
          2.5 “SHAPE Senior Administrator” or “SSA” refers to the lead person in
          the Organisation who will liaise with and ensure effective
          implementation of the Instructions.
        </p>
        <h3>3. LICENCE GRANT, RESPONSIBILITIES, AND USE</h3>
        <p>
          3.1 SHAPE grants the Organisation a non-exclusive, non-transferable
          licence to use the Service or portion thereof for the Organisation’s
          own use during the Pilot period and in accordance with the SHAPE Terms
          of Service.
        </p>
        <p>
          3.2 SHAPE shall provide a limited number of licenses to the
          Organisation to be used by the Organisation’s employees as per the
          Instructions provided to them.
        </p>
        <p>
          3.3 SHAPE shall provide this Pilot to the Organisation at a mutually
          agreed cost, to be confirmed in writing by the SHAPE representative.
        </p>
        <p>
          3.4 The Organisation agrees not to modify, amend, disassemble,
          decompile, reverse engineer, or create derivative works of the Service
          provided under this Agreement.
        </p>
        <p>
          3.5 The Organisation agrees to nominate an SSA who shall commit to
          implement the Service as per the Instructions provided by SHAPE.
        </p>
        <p>
          3.6 The Organisation shall commit to providing feedback on the
          experience of the Service following the end of the Pilot period in a
          timely manner (i.e. one month) along with a purchasing and/or
          partnership decision.
        </p>
        <h3>4. SHAPE RIGHTS</h3>
        <p>
          4.1 The Organisation’s right to the Service is expressly limited to
          the right of use, as set forth in this Agreement. The Service shall at
          all times remain the property of SHAPE and the Organisation shall have
          no right, title, or interest therein, except as otherwise stated.
        </p>
        <p>
          4.2 The Organisation shall keep confidential and protect from
          unauthorised disclosure by its employees, agents, or customers the
          contents of the Service. This obligation survives the termination of
          the Agreement.
        </p>
        <p>
          4.3 SHAPE shall own and retain all right, title, intellectual
          property, and interest in and to all inventions, improvements,
          enhancements, and modifications borne of the feedback by the
          Organisation.
        </p>
        <p>
          4.4 The Organisation shall not represent, demonstrate, sell, transfer,
          or otherwise make available the Service to others.
        </p>
        <h3>5. DISCLAIMERS OF WARRANTIES</h3>
        <p>
          5.1 SHAPE will not be liable for any failure or delay in performance
          due in whole or in part to any cause beyond its control. In no event
          will SHAPE be liable for any indirect, special, or consequential
          damages, including damages arising from the Organisation’s lost
          profits, arising out of this Agreement.
        </p>
        <p>
          5.2 Except as provided above, this Service and any related content
          accessible through the Service is provided “as-is", and to the maximum
          extent permitted by law, SHAPE disclaims all other representations and
          warranties, express or implied regarding the Service, related
          materials, and any such content.
        </p>
        <p>
          5.3 This Agreement is governed by laws of England, in the United
          Kingdom.
        </p>
        <h3>6. ENTIRE AGREEMENT</h3>
        <p>
          6.1 This Agreement (consisting of Sections 1 through 6 inclusive),
          represents the entire Agreement between the Parties regarding the
          matters dealt with and supersedes all prior oral and written proposals
          and communications.
        </p>
      </Container>
    </Layout>
  )
}

export default PilotTerms
